import React from 'react';

function Calendar() {
  return (
    <div>
      <h1>Calendar</h1>
      <p>Check out our calendar of events.</p>
    </div>
  );
}

export default Calendar;
