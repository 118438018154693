import React from 'react';

function Store() {
  return (
    <div>
      <h1>Store</h1>
      <p>Welcome to our store page!</p>
    </div>
  );
}

export default Store;
