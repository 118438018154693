import React from 'react';

function AboutUs() {
  return (
    <div>
      <h1>About Us</h1>
      <p>Learn more about us on this page.</p>
    </div>
  );
}

export default AboutUs;
